import * as actionTypes from "../Types";

interface SnackFeedback {
  show: boolean;
  message: string | null;
  severity?: "success" | "error" | "info" | "warning";
}
export interface State {
  user: any | null;
  loadingAuth: boolean;
  snackFeedBack?: SnackFeedback;
  parentId: string;
  parent: any | null;
}

export const initialState: State = {
  user: null,
  parent: null,
  loadingAuth: true,
  parentId: "",
};

const Login = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        snackFeedBack: undefined,
        loadingAuth: false,
        parentId: action.parentId,
        parent: action.parent,
      } as State;
    case actionTypes.AUTH_SIGNIN_ERROR:
      return {
        ...state,
        snackFeedBack: action.data,
        user: null,
        parent: null,
        loadingAuth: false,
        parentId: "",
      } as State;
    case actionTypes.AUTH_SIGNOUT_SUCCESS:
      return {
        ...state,
        snackFeedBack: undefined,
        user: null,
        parent: null,
        loadingAuth: false,
        parentId: "",
      } as State;
    case actionTypes.AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        snackFeedBack: undefined,
        loadingAuth: false,
        parentId: "",
      } as State;
    case actionTypes.AUTH_SIGNUP_ERROR:
      return {
        ...state,
        snackFeedBack: action.data,
        loadingAuth: false,
        parentId: "",
      } as State;
    case actionTypes.AUTH_SET_USER_STATE:
      return {
        ...state,
        snackFeedBack: undefined,
        user: action.user,
        parent: action.parent,
        loadingAuth: action.loadinState,
      } as State;
    case actionTypes.AUTH_SET_LOADING:
      return {
        ...state,
        loadingAuth: action.value,
      } as State;
    case actionTypes.AUTH_CLEAN_UP_ERRORS:
      return {
        ...state,
        snackFeedBack: undefined,
      } as State;
    case actionTypes.SNACKFEEDBACK:
      return {
        ...state,
        snackFeedBack: action.data,
      };
    default:
      return state as State;
  }
};

export default Login;
