import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import configureStore from "./Redux";
import reportWebVitals from "./reportWebVitals";
import firebase, { firebaseConfig } from "./services/firebase";
import * as serviceWorker from "./serviceWorker";

const { store, persistor } = configureStore();

const rrfProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <App />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
serviceWorker.register();
