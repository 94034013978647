import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getDocs,
  query,
  getDoc,
  doc,
  collectionGroup,
  where,
  getFirestore,
} from "firebase/firestore";
import firebaseApp from "../../../../services/firebase";
import * as actionTypes from "../Types";

const db = getFirestore(firebaseApp);
// Sign In Action
export const signInAction = (credentials, rememeberMe) => async (dispatch) => {
  const auth = getAuth(firebaseApp);

  const res = setPersistence(
    auth,
    rememeberMe ? browserLocalPersistence : browserSessionPersistence
  ).then(async () => {
    try {
      dispatch(setLoading(true));
      const res = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      const userData = await getDocs(
        query(
          collectionGroup(db, "usuariosTenant"),
          where("email", "==", credentials.email)
        )
      );
      let _user = null;
      userData.forEach(async (user) => {
        _user = user;
      });
      const parentId = _user.ref.parent.parent.id;
      const parent = await getDoc(doc(db, "tenant", parentId));
      const data = parent.data();
      localStorage.setItem("COLOR_SCHEMA", JSON.stringify(data.colorSchema));
      dispatch({
        type: actionTypes.SET_SCHEMA_COLOR,
        data: data.colorSchema,
      });
      if (res.user) {
        dispatch({ type: actionTypes.AUTH_SIGNIN_SUCCESS, parentId, parent });
        return Promise.resolve("AUTH_SIGNIN_SUCCESS");
      }
      return Promise.reject();
    } catch (err) {
      dispatch({
        type: actionTypes.AUTH_SIGNIN_ERROR,
        data: {
          message: "Erro no login.",
          severity: "error",
          show: true,
        },
      });
      return Promise.reject();
    }
  });
  return res;
};

// Sign Out Action
export const signOutAction = () => (dispatch) => {
  const auth = getAuth(firebaseApp);

  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("COLOR_SCHEMA");
        dispatch({
          type: actionTypes.AUTH_SIGNOUT_SUCCESS,
        });
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Just ser user state, without performing firebase login
export const setUser = (user, loadingState, parent) => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_SET_USER_STATE,
    user,
    loadingState,
    parent,
  });
};

//  Set the loading state *when auth process is loading)
export const setLoading = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_SET_LOADING,
    value,
  });
};

// Clean sigIn and signUp errors
export const cleanErrors = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_CLEAN_UP_ERRORS,
  });
};

// Recovery
export const recoverPassword = (email) => (dispatch) => {
  const auth = getAuth(firebaseApp);
  sendPasswordResetEmail(auth, email)
    .then(() => {
      dispatch(
        setSnackFeedback({
          show: true,
          message:
            "Um email foi enviado com as instrunções de redefinição de senha",
          severity: "success",
        })
      );
    })
    .catch((e) =>
      dispatch(
        setSnackFeedback({
          show: true,
          message: "Um erro ocorreu, tente novamente.",
          severity: "error",
        })
      )
    );
};

export const setSchemaColor = (color) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SCHEMA_COLOR,
    data: color,
  });
};

export const setSnackFeedback = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SNACKFEEDBACK,
    data,
  });
};
