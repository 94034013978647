import { DocumentData, Query } from "firebase/firestore";
import * as actionTypes from "../Types";

export interface CustomerInterface {
  id: string;
  nome: string;
  telefone: string;
  endereco: string;
  cpf?: string;
  ativo: boolean;
  dataCriacao: any;
  dataAtualizacao: any;
}

interface Feedback {
  title: string;
  text: string;
  type: string;
  status: boolean;
}

interface State {
  data: {
    clientes: Array<CustomerInterface>;
    selectedCustomer?: CustomerInterface;
    clientesAtivos: Array<CustomerInterface>;
    proximaQuery: Query<DocumentData> | undefined;
    carregando: boolean;
    buscarNovos: boolean;
    feedback?: Feedback;
  };
}

export const initialState: State = {
  data: {
    clientes: [],
    clientesAtivos: [],
    proximaQuery: undefined,
    carregando: false,
    buscarNovos: true,
  },
};

const CustomersReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMERS:
      return {
        ...state,
        data: {
          ...initialState.data,
          clientes: action.data.clientes,
          proximaQuery: action.data.proximaQuery,
          buscarNovos: action.data.buscarNovos,
        },
      } as State;
    case actionTypes.CLEAN_CUSTOMERS:
      return {
        ...state,
        data: { ...initialState.data },
      } as State;
    case actionTypes.SET_CUSTOMER:
      return {
        ...state,
        data: { ...state.data, selectedCustomer: action.data },
      } as State;
    case actionTypes.SET_CLIENTES_ATIVOS:
      return {
        ...state,
        data: { ...state.data, clientesAtivos: action.data },
      };
    case actionTypes.SET_CUSTOMERS_LOADING:
      return {
        ...state,
        data: { ...state.data, carregando: action.data },
      } as State;
    case actionTypes.SET_CUSTOMER_FEEDBACK:
      return {
        ...state,
        data: { ...state.data, feedback: action.data },
      };
    default:
      return state;
  }
};

export default CustomersReducer;
