import {
  StorageOutlined,
  ShoppingCartOutlined,
  AttachMoneyOutlined,
  FaceOutlined,
} from "@material-ui/icons";
import { Menu, MenuItem, SubMenu, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useHistory } from "react-router-dom";
import TechBox from "../../../Assets/logo/logo.svg";
import { Logo, LogoDiv } from "./Navbar_style";

const Navbar = ({ toggled, setToggled }) => {
  const history = useHistory();

  const onNavbarSelect = (selectedMenuParam) => {
    setToggled(false);
    history.push({
      pathname: `/${selectedMenuParam}`,
    });
  };

  return (
    <ProSidebar onToggle={setToggled} breakPoint="md" toggled={toggled}>
      <LogoDiv>
        <Logo src={TechBox} alt="Logo" onClick={() => {}} />
      </LogoDiv>

      <Menu iconShape="square">
        <MenuItem
          onClick={() => onNavbarSelect("resumo")}
          icon={<AttachMoneyOutlined />}
        >
          Resumo
        </MenuItem>
        <SubMenu title="Vendas" icon={<ShoppingCartOutlined />}>
          <MenuItem onClick={() => onNavbarSelect("vendas")}>
            Vendas por dia
          </MenuItem>
          <MenuItem onClick={() => onNavbarSelect("venda")}>
            Nova venda
          </MenuItem>
        </SubMenu>

        <SubMenu title="Estoque" icon={<StorageOutlined />}>
          <MenuItem onClick={() => onNavbarSelect("estoque")}>Estoque</MenuItem>
          <MenuItem onClick={() => onNavbarSelect("produto")}>
            Novo Produto
          </MenuItem>
        </SubMenu>

        <SubMenu title="Clientes" icon={<FaceOutlined />}>
          <MenuItem onClick={() => onNavbarSelect("clientes")}>
            Clientes
          </MenuItem>
          <MenuItem onClick={() => onNavbarSelect("cliente")}>
            Novo Cliente
          </MenuItem>
        </SubMenu>
      </Menu>
    </ProSidebar>
  );
};

export default Navbar;
