import * as actionTypes from "../Types";

export interface State {
  colors: any;
}

export const initialState: State = {
  colors: {
    Brand: {
      primary: {
        light: "#028bc7",
        main: "#005e87",
        dark: "#013952",
        contrastText: "#ffffff",
      },
      BrandButtonNegative: "#eeeeee",
      BrandButtonNegativeHovered: "#dbdbdb",
      BrandInfo: "#5c5c84",
      BrandInfoHovered: "#4c4c6d",
      BrandPrimary: "#005e87",
      BrandPrimaryHovered: "#0779ab",
      BrandSecondary: "#1d1d1d",
      BrandSecondaryHovered: "#464545",
    },
    Status: {
      SuccessStatus: "#5ACC63",
      WaitingStatus: "#F7D04E",
      FailureStatus: "#F55A5A",
    },
    Neutral: {
      PureBlack: "#38484F",
      DarkGray: "#707070",
      LightGray: "#EEEEEE",
      PureWhite: "#FCFCFC",
    },
  },
};

const Configs = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SCHEMA_COLOR:
      return {
        ...state,
        colors: {
          ...state.colors,
          Brand: action.data,
        },
      } as State;
    default:
      return state as State;
  }
};

export default Configs;
