import styled from "styled-components";
import store from "../../../Redux";

const Colors = localStorage.getItem("COLOR_SCHEMA")
  ? {
      ...store().store.getState().Settings.colors,
      Brand: JSON.parse(localStorage.getItem("COLOR_SCHEMA")),
    }
  : store().store.getState().Settings.colors;

export const HeaderStyle = styled.div`
  height: 56px;
  border-bottom: 1px solid ${Colors.Neutral.LightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-app-region: drag;
  #toggle {
    display: none;
    margin: 8px;
    padding: 8px;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
  }
  @media (max-width: 768px) {
    #toggle {
      display: block;
    }
  }
`;

export const CloseButton = styled.div`
  -webkit-app-region: no-drag;
  display: flex;
  align-items: center;
  height: 28px;
  margin-right: 18px;
  cursor: pointer;

  #sair {
    margin-right: 8px;
  }
`;

export const NavbarAppIcon = styled("div")`
  display: flex;
  justify-content: center;
  align-self: center;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: bold;
  color: ${Colors.Neutral.PureBlack};
  margin-left: 24px;
  @media (max-width: 768px) {
    margin: 0 16px 0 64px;
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 16px;
  }
`;
