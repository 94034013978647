import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  getDocs,
  query,
  collectionGroup,
  where,
  getFirestore,
} from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setUser } from "./Global/Login/Redux/Actions";
import Navigation from "./Global/Navigation";
import firebaseApp from "./services/firebase";

const App = () => {
  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    dispatch(setLoading(true));

    const unsubscribe = onAuthStateChanged(auth, async (userFirebase) => {
      if (userFirebase) {
        const userData = await getDocs(
          query(
            collectionGroup(db, "usuariosTenant"),
            where("email", "==", userFirebase.email)
          )
        );
        let _user: any = null;
        userData.forEach(async (user) => {
          _user = user;
        });
        const parentId = _user.ref.parent.parent?.id;
        const parent = await getDoc(doc(db, "tenant", `${parentId}`));
        // User is signed in
        dispatch(setUser({ userFirebase, userData: _user }, false, parent));
      } else {
        // User is not signed in
        dispatch(setUser(null, true, null));
      }
    });
    return unsubscribe;
  });
  return <Navigation />;
};

export default App;
