import moment from "moment";
import * as actionTypes from "../Types";
import { SaleProps } from "../../../Sales/Redux/Reducers/SaleReducer";
import { getCurrentISODate } from "../../../../Utils/CommonFunctions";

export interface SummaryResponse {
  funcionario: string;
  vendas: Array<SaleProps>;
  total: number;
}
interface State {
  data: {
    resumo: Array<SummaryResponse>;
    carregando: boolean;
    dateIn: string;
    dateOut: string;
  };
}

export const initialState: State = {
  data: {
    resumo: [],
    carregando: false,
    dateIn: moment(getCurrentISODate(new Date())).format("YYYY-MM-DD"),
    dateOut: moment(getCurrentISODate(new Date())).format("YYYY-MM-DD"),
  },
};

const SummaryReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_RESUMO:
      return {
        ...state,
        data: {
          ...state.data,
          resumo: action.data.resumo,
        },
      } as State;
    case actionTypes.SET_RESUMO_LOADING:
      return {
        ...state,
        data: {
          ...state.data,
          carregando: action.data,
        },
      } as State;
    case actionTypes.CLEAN_RESUMO:
      return {
        ...initialState,
      } as State;
    case actionTypes.SET_DATE_IN:
      return {
        ...state,
        data: {
          ...state.data,
          dateIn: action.data,
        },
      } as State;
    case actionTypes.SET_DATE_OUT:
      return {
        ...state,
        data: {
          ...state.data,
          dateOut: action.data,
        },
      } as State;
    default:
      return state;
  }
};

export default SummaryReducer;
