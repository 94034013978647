import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import { firebaseReducer as Firebase } from "react-redux-firebase";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { firestoreReducer as Firestore } from "redux-firestore";
import { persistReducer, persistStore } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import Authentication, { Settings } from "./Global/Login/Redux/Reducers";
import CustomersReducer from "./Modules/Customers/Redux/Reducers";
import SalesReducer from "./Modules/Sales/Redux/Reducers";
import StorageReducer from "./Modules/Storage/Redux/Reducers";
import SummaryReducer from "./Modules/Summary/Redux/Reducers";

const rootReducer = combineReducers({
  Authentication,
  Settings,
  CustomersReducer,
  SalesReducer,
  StorageReducer,
  SummaryReducer,
  Firestore,
  Firebase,
});
// The following declaration is used to compose with DevTools only on DESKTOP Web Browsers.
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["Authentication"],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const enhancers = composeEnhancers(applyMiddleware(thunk));

const Store = () => {
  const store = createStore(persistedReducer, enhancers);
  const persistor = persistStore(store);

  return { store, persistor };
};

export default Store;
