import { Menu, ExitToApp } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "../../../Redux";
import * as actions from "../../Login/Redux/Actions";
import {
  CloseButton,
  HeaderStyle,
  NavbarAppIcon,
  NavbarRight,
} from "./Header_style";

export default function Header({
  toggled,
  setToggled,
}: {
  toggled: boolean;
  setToggled: Function;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, parent } = useSelector((state) => state.Authentication.Login);
  const { nome } = user?.userData?.id ? user.userData.data() : { nome: "" };
  const { nome: nomeParent } = parent?.id ? parent.data() : { nome: "" };
  return (
    <HeaderStyle>
      <div id="toggle">
        <Menu onClick={() => setToggled(!toggled)} />
      </div>
      <NavbarAppIcon>SMIBOX</NavbarAppIcon>
      <NavbarRight>
        <span>
          {nomeParent} | {nome}
        </span>
        <CloseButton
          onClick={() => {
            dispatch(actions.signOutAction());
            history.push({
              pathname: `/login`,
            });
          }}
        >
          <span id="sair">Sair</span> <ExitToApp />
        </CloseButton>
      </NavbarRight>
    </HeaderStyle>
  );
}
