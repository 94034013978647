import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import store from "../../../Redux";

const Colors = localStorage.getItem("COLOR_SCHEMA")
  ? {
      ...store().store.getState().Settings.colors,
      Brand: JSON.parse(localStorage.getItem("COLOR_SCHEMA")),
    }
  : store().store.getState().Settings.colors;

export const NavbarStyle = styled.div`
  width: 270px;
  background-color: ${Colors.Brand.BrandSecondary};
  z-index: 15;
  height: auto;
`;

export const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  /* background-color: ${Colors.Neutral.LightGray}; */
  background-color: #1d1d1d;
`;

export const Logo = styled.img`
  width: 75%;
`;
