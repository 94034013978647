import styled, { createGlobalStyle } from "styled-components";
import store from "../../Redux.ts";
import { Font } from "../StyledGuide/typography";

const Colors = localStorage.getItem("COLOR_SCHEMA")
  ? {
      ...store().store.getState().Settings.colors,
      Brand: JSON.parse(localStorage.getItem("COLOR_SCHEMA")),
    }
  : store().store.getState().Settings.colors;

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: ${Font.Family};
    color: ${Colors.Neutral.PureBlack};
  }
  .tableFixHead{
    overflow: auto;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.Brand.BrandPrimary};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.Brand.BrandPrimaryHovered};
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: calc(100vw - 270px);

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const ContainerRender = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 31px);
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
