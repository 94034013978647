import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useSelector } from "../../Redux";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Body from "./Body";
import {
  Container,
  ContainerFlex,
  ContainerRender,
  GlobalStyle,
  LoadingContainer,
} from "./Navigation_style";

const LoginPage = lazy(() => import("../Login/Page"));
const SalesPage = lazy(() => import("../../Modules/Sales/Page"));
const StoragePage = lazy(() => import("../../Modules/Storage/Page"));
const CustomersPage = lazy(() => import("../../Modules/Customers/Page"));
const SummaryPage = lazy(() => import("../../Modules/Summary/Page"));

const ProductRegisterForm = lazy(
  () => import("../../Modules/Storage/Components/Form")
);
const CustomerRegisterForm = lazy(
  () => import("../../Modules/Customers/Components/Form")
);
const SaleRegisterForm = lazy(
  () => import("../../Modules/Sales/Components/Form")
);

export default function Navigation() {
  const [toggled, setToggled] = useState(false);
  const { user, loadingAuth } = useSelector(
    (state) => state.Authentication.Login
  );
  const isLoggedIn = !!user;

  const routes = (
    <Switch>
      <Route path="/resumo" exact component={SummaryPage} />
      <Route path="/vendas" exact component={SalesPage} />
      <Route path="/venda" exact component={SaleRegisterForm} />
      <Route path="/nova-venda" exact component={SaleRegisterForm} />
      <Route path="/estoque" exact component={StoragePage} />
      <Route path="/produto" exact component={ProductRegisterForm} />
      <Route path="/clientes" exact component={CustomersPage} />
      <Route path="/cliente" exact component={CustomerRegisterForm} />
      <Redirect from="/" to="/resumo" />
    </Switch>
  );
  return (
    <Router>
      <Suspense
        fallback={
          <LoadingContainer>
            <CircularProgress size={100} />
          </LoadingContainer>
        }
      >
        {isLoggedIn ? (
          loadingAuth ? (
            <LoadingContainer>
              <CircularProgress size={100} />
            </LoadingContainer>
          ) : (
            <>
              <GlobalStyle />
              <ContainerFlex>
                <Body>
                  <Navbar toggled={toggled} setToggled={setToggled} />
                  <Container>
                    <Header toggled={toggled} setToggled={setToggled} />
                    <ContainerRender>{routes}</ContainerRender>
                  </Container>
                </Body>
              </ContainerFlex>
            </>
          )
        ) : (
          <Switch>
            <Route path="/recuperar-senha" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <Redirect path="/" to="/login" />
          </Switch>
        )}
      </Suspense>
    </Router>
  );
}
